<template>
  <td class = "sticky-column">
    <div>{{ formatSchedule(appointmentData.schedule) }}</div>
  </td>
  <td>
    <div>{{ appointmentData.name }}</div>
  </td>
  <td>
    <div>{{ appointmentData.appointment_status }}</div>
  </td>
  <td>
    <div>{{ appointmentData.status_date }}</div>
  </td>
  <td>
    <div v-if="isScheduleWithin24Hours(appointmentData.schedule)">
      <a :href="generateAppointmentLink()" class="pointer">
        {{ $t('AppointmentData.Link') }}
      </a>
    </div>
    <div v-else>
      {{ $t('AppointmentData.LinkNotAvailable') }}
    </div>
  </td>
  <td>
    <div class="is-flex is-justify-content-start" style="gap: 1em;">
      <button type="button" class="button is-warning is-small"
        @click="iniHistoricalAppointmentData(appointmentData)">{{ $t('General.Details') }}</button>
    </div>
  </td>
</template>


<script lang="ts">
import { defineComponent, defineProps, onMounted } from 'vue'
import { useStore } from "vuex";
import { Notyf } from "notyf";
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: "CustDataPetRecordTableRow",
  methods: {
    formatSchedule(schedule: string): string
    {
      // Split the schedule string by space
      const parts = schedule.split(' ');
      // Split the time part (parts[1]) by ':'
      const timeParts = parts[1].split(':');
      // Combine the date part (parts[0]) and only the hour and minute parts of the time
      return parts[0] + ' ' + timeParts[0] + ':' + timeParts[1];
    }
  },
})

</script>
<script lang="ts" setup>

const notyf = new Notyf()
const appointmentLink = process.env.BASE_URL + "appointment/";  // Use the environment variable
const props = defineProps(['appointmentData'])
const store = useStore()

/*
const generateAppointmentLink = (pin: string) => {
  const { locale } = useI18n();
  return `${appointmentLink}${pin}?lang=${locale.value}`;
}
*/

const generateAppointmentLink = () =>
{
  const { locale } = useI18n();
  const accessType = store.getters.getAccessType;

  // Use `vet_pin` if access type is 'vet', otherwise use `customer_pin`.
  const pin =
    accessType === "customer"
      ? props.appointmentData.customer_pin
      : props.appointmentData.vet_pin;

  return `${appointmentLink}${pin}?lang=${locale.value}`;
}

async function iniHistoricalAppointmentData(showAppointmentData: any) {
  console.log("iniHistoricalAppointmentData")
  console.log(store.getters.getShowingAppointmentData)

  await store.dispatch('iniEditedAppointment', showAppointmentData.appointment_id)
  console.log("back to iniAppointmentData")
  console.log(store.getters.getEditedAppointmentData)
  
  await store.dispatch('resetAppointmentFiles')
  await store.dispatch('iniEditedAppointmentFiles', showAppointmentData.appointment_id)

  store.dispatch('toggleAppointmentDetailing', false)
  store.dispatch('toggleShowingAppointmentData', false)
  store.dispatch('toggleShowingPetRecord', true)
  store.dispatch('toggleShowingHistoricalAppointmentData', false)
}

// Method to check if schedule is within 24 hours
const isScheduleWithin24Hours = (schedule: string): boolean =>
{
  const scheduleDate = new Date(schedule); // Parse schedule string into Date object
  const now = new Date();
  const timeDifference = now.getTime() - scheduleDate.getTime();
  const hoursDifference = timeDifference / (1000 * 60 * 60); // Convert milliseconds to hours
  return hoursDifference <= 24;
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
.sticky-column {
  position: sticky;
  max-width: 150px; /* Ensure it doesn't expand beyond this width */
  left: 0;
  background: #fff; /* Match table background */
  z-index: 2;
  border-right: 1px solid #ddd; 
  border-left: 1px solid #ddd;
  will-change: left; /* Optimize performance */
}
</style>
