<template>
  <div class="columns">
    <div class="column">
      <div class="field">
        <label class="label">{{ $t('PetData.PetName') }}</label>
        <div class="control">
          <input :minlength='2' v-model="getDetailedPetData.name" ref='petname' class="input" type="text"
          :placeholder="$t('PetData.PetNamePlaceholder')" :disabled="!getEditingCustomer" required>
        </div>
      </div>
    </div>
    <div class="column" v-if="otherSpecies">
      <div class="field">
        <label class="label">{{ $t('PetData.Species') }}</label>
        <div class="control">
          <input id='species_field' :minlength='2' v-model="getDetailedPetData.species" class="input" type="text"
          :placeholder="$t('PetData.SpeciesPlaceholder')" :disabled="!getEditingCustomer" required
            v-on:change="updateSpecies(getDetailedPetData.species)">
        </div>
      </div>
    </div>

    <div class="column" v-if="!otherSpecies">
      <div class="field">
        <label class="label">{{ $t('PetData.Species') }}</label>
        <div class="control">
          <div class="select is-fullwidth">
            <select id='species_dropdown' v-model="getDetailedPetData.species" required class="px-6"
              v-on:change="updateSpecies(getDetailedPetData.species)" :disabled="!getEditingCustomer">
              <option value="">{{ $t('General.PleaseChoose') }}</option>
              <option value="Dog">{{ $t('PetData.SpeciesDog') }}</option>
              <option value="Cat">{{ $t('PetData.SpeciesCat') }}</option>
              <option value="Other">{{ $t('PetData.SpeciesOther') }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="column" v-if="otherSpecies || otherBreed">
      <div class="field">
        <label class="label">{{ $t('PetData.Breed') }}</label>
        <div class="control">
          <input :minlength='2' v-model="getDetailedPetData.breed" class="input" type="text"
          :placeholder="$t('PetData.BreedPlaceholder')" required v-on:change="updateBreed(getDetailedPetData.breed)"
            :disabled="!getEditingCustomer">
        </div>
      </div>
    </div>
    <div class="column" v-if="!otherSpecies && !otherBreed">
      <div class="field">
        <label class="label">{{ $t('PetData.Breed') }}</label>
        <div class="control">
          <div class="select is-fullwidth">
            <select v-model="getDetailedPetData.breed" required class="px-6"
              v-on:change="updateBreed(getDetailedPetData.breed)" :disabled="!getEditingCustomer">
              <option v-for="breed of getEditedCustBreeds" :key="breed.breed_id" :value="breed.name">{{
                breed.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="columns">
    <div class="column">
      <div class="field">
        <label class="label">{{ $t('PetData.Gender') }}</label>
        <div class="control">
          <div class="select is-fullwidth">
            <select v-model="getDetailedPetData.gender" required class="px-6" :disabled="!getEditingCustomer">
              <option value="">{{ $t('General.PleaseChoose') }}</option>
              <option value="F">{{ $t('PetData.GenderFemale') }}</option>
              <option value="M">{{ $t('PetData.GenderMale') }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="field">
        <label class="label">{{ $t('PetData.Birth') }}</label>
        <div class="control">
          <VueDatePicker id="birth_id" v-model="getDetailedPetData.birth" :placeholder="$t('General.PleaseSelectDate')"
            model-type="yyyy-MM-dd" auto-apply :min-date="new Date(1950, 0, 1)" :max-date="new Date()"
            :enable-time-picker="false" prevent-min-max-navigation format="yyyy-MM-dd" @update:model-value="updateAge"
            :disabled="!getEditingCustomer" />
        </div>
      </div>
    </div>
    <div class="column">
      <div class="field">
        <label class="label">{{ $t('PetData.Age') }}</label>
        <div class="control">
          <span v-if="ageYears == 1">{{ ageYears }} {{ $t('General.Year') }}</span>
          <span v-if="ageYears > 1">{{ ageYears }} {{ $t('General.Years') }}</span>
          <span v-if="ageMonths == 1">{{ ageMonths }} {{ $t('General.Month') }}</span>
          <span v-if="ageMonths > 1">{{ ageMonths }} {{ $t('General.Months') }}</span>
          <span v-if="(ageYears == 0 && ageMonths == 0 && ageDays == 1)">{{ ageDays }} {{ $t('General.Day') }}</span>
          <span v-if="(ageYears == 0 && ageMonths == 0 && ageDays > 1)">{{ ageDays }} {{ $t('General.Days') }}</span>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="field">
        <label class="label">{{ $t('PetData.FertileStatus') }}</label>
        <div class="control">
          <div class="select is-fullwidth">
            <select v-model="getDetailedPetData.fertile_status" required class="px-6"
              :disabled="!getEditingCustomer">
              <option value="">{{ $t('General.PleaseChoose') }}</option>
              <option v-for="option in fertileStatusOptions" :key="option.value" :value="option.value">
              {{ option.text }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="field">
        <label class="label">{{ $t('PetData.Weight') }}</label>
        <div class="control">
          <input min=0 max=999 v-model="getDetailedPetData.weight" step="0.1" class="input" type="number"
          :placeholder="$t('PetData.WeightPlaceholder')" required :disabled="!getEditingCustomer">
        </div>
      </div>
    </div>
  </div>
  <div class="content-body">
    <div class="table-container mb-2" style="max-height: 280px; overflow-y: auto;">
      <table class="table" v-if="!getEditingCustomer">
        <thead style="position: sticky; top: 0; z-index: 10; background-color: white;">
          <tr>
            <th class="sticky-column">{{ $t('AppointmentData.Appointment') }}</th>
            <th class="">{{ $t('AppointmentData.Vet') }}</th>
            <th class="">{{ $t('AppointmentData.Status') }}</th>
            <th class="">{{ $t('AppointmentData.StatusTime') }}</th>
            <th class="">{{ $t('AppointmentData.Link') }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="appointment of getPetRecord" :key="appointment.appointment_id">
            <AppointmentDataPetRecordTableRow :appointment-data="appointment"></AppointmentDataPetRecordTableRow>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">

import { defineComponent, ref } from "vue";
import { mapActions, mapGetters } from "vuex";
import { Notyf } from "notyf";
import { differenceInYears, differenceInMonths, differenceInDays } from "date-fns";
import AppointmentDataPetRecordTableRow from "../components/AppointmentDataPetRecordTableRow.vue";

interface UpdateEditedCustBreedsListParams {
    species: string,
    language: string,
}


export default defineComponent
  (
    {
      name: "CustDataPetDataForm",
      components:
      {
        AppointmentDataPetRecordTableRow
      },

      data() {
        return {
          componentName: "CustDataPetDataForm",
          otherSpecies: false,
          otherBreed: false,
          ageYears: 0,
          ageMonths: 0,
          ageDays: 0,
        }
      },
      computed:
      {
        ...mapGetters(['getDetailedPetData', 'getEditedCustBreeds', 'getEditingCustomer', 'getPetRecord']),
        fertileStatusOptions()
        {
          if (this.getDetailedPetData.gender === "M")
          {
            return [
              { value: this.$t('PetData.FertileStatusIntactMale'), text: this.$t('PetData.FertileStatusIntactMale') },
              { value: this.$t('PetData.FertileStatusNeuteredSpayedMale'), text: this.$t('PetData.FertileStatusNeuteredSpayedMale') }
            ];
          }
          else 
          {
            return [
              { value: this.$t('PetData.FertileStatusIntactFemale'), text: this.$t('PetData.FertileStatusIntactFemale') },
              { value: this.$t('PetData.FertileStatusNeuteredSpayedFemale'), text: this.$t('PetData.FertileStatusNeuteredSpayedFemale') }
            ];
          }
        }
      },
      methods:
      {
        ...mapActions(['iniEditedCustNewPet', 'updateEditedCustBreedsList', 'resetEditedCustBreed']),

        clearFormPet() {
          this.iniEditedCustNewPet()
          this.otherSpecies = false;
          this.otherBreed = false;
          const updateEditedCustBreedsListParams: UpdateEditedCustBreedsListParams = {
            species: '',
            language: this.$i18n.locale
          }
          this.updateEditedCustBreedsList(updateEditedCustBreedsListParams)
        },

        updateSpecies(newSpecies: string) {

          if (newSpecies == 'Other') {
            this.getDetailedPetData.species = "";
            this.otherSpecies = true;
          }
          else {
            if (newSpecies == '' || newSpecies == 'Cat' || newSpecies == 'Dog') {
              this.otherSpecies = false;
            }
          }
          this.resetEditedCustBreed()
          this.otherBreed = false;
          const updateEditedCustBreedsListParams: UpdateEditedCustBreedsListParams = {
            species: newSpecies,
            language: this.$i18n.locale
          }
          this.updateEditedCustBreedsList(updateEditedCustBreedsListParams)
        },

        updateBreed(newBreed: string) {

          if (newBreed == 'Other') {
            this.otherBreed = true;
            this.resetEditedCustBreed()
          }
          else {
            if (newBreed == '') {
              this.otherBreed = false;
            }
          }
        },

        updateAge() {
          if (this.getDetailedPetData.birth != null) {
            console.log(this.getDetailedPetData.birth);
            const birthDate = new Date(this.getDetailedPetData.birth);
            console.log(birthDate)
            this.ageYears = differenceInYears(new Date(), birthDate);
            console.log(this.ageYears)
            this.ageMonths = differenceInMonths(new Date(), birthDate) - 12 * this.ageYears;
            console.log(this.ageMonths);
            this.ageDays = differenceInDays(new Date(), birthDate);
            console.log(this.ageDays);
          }
          else {
            this.ageYears = 0;
            this.ageMonths = 0;
            this.ageDays = 0;
          }
        },

      },
      watch: {},
      created() { },
      mounted() {
        console.log(this.getDetailedPetData.species)
        const updateEditedCustBreedsListParams: UpdateEditedCustBreedsListParams = {
            species: this.getDetailedPetData.species,
            language: this.$i18n.locale
          }
        this.updateEditedCustBreedsList(updateEditedCustBreedsListParams)
        this.updateAge();
        (this.$refs.petname as any).focus();
      }
    }
  )

</script>

<style scoped>
.content-body {
  margin-top: 10px;
}

.content-body {
  margin-top: 10px;
}
.table-container {
  overflow-x: auto;
}

table {
  border-collapse: separate;
  min-width: 800px;
}

.sticky-column {
  position: sticky;
  max-width: 150px; /* Ensure it doesn't expand beyond this width */
  left: 0;
  background: #fff; /* Match table background */
  z-index: 2;
  border-right: 1px solid #ddd; 
  border-left: 1px solid #ddd;
  will-change: left; /* Optimize performance */
}
</style>