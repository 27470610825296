interface Pet {
    pet_pos: number,
    pet_id: number,
    user_id: number,
    name: string
    species: string,
    breed: string,
    gender: string,
    birth: Date,
    fertile_status: string,
    weight: number,
}

interface Customer {
    user_id: number,
    name: string
    email: string,
    password: string,
    confirmation: string,
    type: string,
    phone: string,
    address: string,
    postal_code: string,
    city: string,
    state: string,
    country: string,
    tax_id: string,
    vet_center: string,
    pets: Pet[],
}

interface Breed {
    breed_id: number,
    name: string
}

interface Appointment {
    appointment_id: number,
    customer_user_id: number,
    pet_id: number,
    vet_user_id: number,
    vet_name: string,
    description: string,
    schedule: string,
    customer_pin: number,
    vet_pin: number,
    appointment_status: string,
    status_date: string
}

interface UpdateEditedCustBreedsListParams {
    species: string,
    language: string,
}

const customerResetState = () => ({
    editedCustomerData: {
        user_id: 0,
        customer_id: 0,
        name: '',
        email: '',
        password: '',
        confirmation: '',
        type: 'customer',
        phone: '',
        address: '',
        postal_code: '',
        city: '',
        state: '',
        country: '',
        tax_id: '',
        vet_center: '',
    },
    pets: [] as Pet[],
    newPetData: {
        pet_pos: 0,
        pet_id: 0,
        user_id: 0,
        name: '',
        species: '',
        breed: '',
        gender: '',
        birth: '',
        fertile_status: '',
        weight: 0,
    },
    breeds: [] as Breed[],
    editingPet: false,
    editingCustomer: false,
    showingPetRecord: false,
    petRecord: [] as Appointment[],
    petIdsToBeDeleted: [] as number[],
    customerMode: 'appointment_req',
    detailedPetData: {
        pet_id: 0,
        user_id: 0,
        name: '',
        species: '',
        breed: '',
        gender: '',
        birth: '',
        fertile_status: '',
        weight: 0,
    },
});

export const editedCustomer = {
    state: customerResetState(),
    getters: {
        getEditedCustomerData(state: any): Customer {
            return (state.editedCustomerData)
        },
        getEditedCustomerPets(state: any): Pet[] {
            return (state.pets)
        },
        getEditedCustNewPetData(state: any): Pet {
            return (state.newPetData)
        },
        getEditedCustBreeds(state: any): Breed[] {
            return (state.breeds)
        },
        getEditedCustEditingPet(state: any): boolean {
            return (state.editingPet)
        },
        getEditingCustomer(state: any): boolean {
            return (state.editingCustomer)
        },
        getShowingPetRecord(state: any): boolean {
            return (state.showingPetRecord)
        },
        getPetRecord(state: any): Appointment[] {
            return (state.petRecord)
        },
        getEditedCustPetsToDelete(state: any): number[] {
            return (state.petIdsToBeDeleted)
        },
        getCustomerMode(state: any): string {
            return (state.customerMode)
        },
        getCustomerHasPets(state: any): boolean {
            return (state.pets.length > 0)
        },
        getDetailedPetData(state: any): Pet {
            return (state.detailedPetData)
        }
    },
    mutations: {

        setEditedCustData(state: any, editedCustData: any) {
            state.editedCustomerData.user_id = editedCustData.user_id;
            state.editedCustomerData.email = editedCustData.email;
            state.editedCustomerData.name = editedCustData.name;
            state.editedCustomerData.customer_id = editedCustData.customer_id;
            state.editedCustomerData.phone = editedCustData.phone;
            state.editedCustomerData.address = editedCustData.address;
            state.editedCustomerData.postal_code = editedCustData.postal_code;
            state.editedCustomerData.city = editedCustData.city;
            state.editedCustomerData.state = editedCustData.state;
            state.editedCustomerData.country = editedCustData.country;
            state.editedCustomerData.tax_id = editedCustData.tax_id;
            state.editedCustomerData.vet_center = editedCustData.vet_center;
        },

        setEditedUserPetsData(state: any, editedUserPetsData: any) {
            console.log("setEditedUserPetsData");
            console.log(editedUserPetsData);
            console.log(typeof (editedUserPetsData));
            state.pets = [];
            editedUserPetsData.forEach((pet: any, index: number) => {
                pet.pet_pos = index;
                console.log(pet);
                state.pets.push(pet)
            });
        },

        setEditedCustomerData(state: any, editedCustomerData: any) {
            state.editedCustomerData = editedCustomerData
        },
        
        setEditedCustAddPet(state: any, newPet: any) {
            newPet.pet_pos = state.pets.length;
            state.pets.push(newPet)
        },

        setEditedCustEditPet(state: any, editedPet: any) {
            state.pets[editedPet.pet_pos] = editedPet
        },

        setEditedCustRemovePet(state: any, pet_pos: any) {
            console.log("setEditedCustRemovePet")
            state.pets.splice(pet_pos, 1)
            state.pets.forEach((pet: any, index: number) => {
                pet.pet_pos = index;
            })
        },

        setEditedCustIniPet(state: any) {
            state.newPetData = {
                pet_pos: 0,
                pet_id: 0,
                user_id: 0,
                name: '',
                species: '',
                breed: '',
                gender: '',
                birth: '',
                fertile_status: '',
                weight: 0,
            }
        },

        setEditedCustBreeds(state: any, breeds: Breed[]) {
            state.breeds = breeds
        },

        setEditedCustIniBreed(state: any) {
            state.newPetData.breed = ''
        },

        setEditedCustIniBreeds(state: any) {
            state.breeds = []
        },
        setEditedCustEditingPet(state: any, editingPet: boolean) {
            state.editingPet = editingPet
        },
        setEditedCustPetNewData(state: any, petNewData: Pet) {
            state.newPetData.pet_pos = petNewData.pet_pos
            state.newPetData.pet_id = petNewData.pet_id
            state.newPetData.user_id = petNewData.user_id
            state.newPetData.name = petNewData.name
            state.newPetData.species = petNewData.species
            state.newPetData.breed = petNewData.breed
            state.newPetData.gender = petNewData.gender
            state.newPetData.birth = petNewData.birth
            state.newPetData.fertile_status = petNewData.fertile_status
            state.newPetData.weight = petNewData.weight
        },
        setEditingCustomer(state: any, editingCustomer: boolean) {
            state.editingCustomer = editingCustomer
        },
        setShowingPetRecord(state: any, showingPetRecord: boolean) {
            state.showingPetRecord = showingPetRecord
        },
        setPetRecord(state: any, PetRecord: any) {
            console.log("setPetRecord");
            console.log(PetRecord);
            console.log(typeof (PetRecord));
            state.petRecord = [];
            PetRecord.forEach((petrecord: any) => {
                console.log(petrecord);
                state.petRecord.push(petrecord)
            });
        },
        setEditedCustPetsToDelete(state: any, petIdsToDelete: number[]) {
            state.petIdsToBeDeleted = petIdsToDelete;
        },
        setEditedCustAddPetIdToDelete(state: any, petId: number) {
            state.petIdsToBeDeleted.push(petId)
        },
        setCustomerMode(state: any, customerMode: string) {
            state.customerMode = customerMode
        },
        customerResetStateMut(state: any) {
            Object.assign(state, customerResetState())
        },
        setDetailedPetData(state: any, detailedPetData: Pet) {
            state.detailedPetData.pet_id = detailedPetData.pet_id
            state.detailedPetData.user_id = detailedPetData.user_id
            state.detailedPetData.name = detailedPetData.name
            state.detailedPetData.species = detailedPetData.species
            state.detailedPetData.breed = detailedPetData.breed
            state.detailedPetData.gender = detailedPetData.gender
            state.detailedPetData.birth = detailedPetData.birth
            state.detailedPetData.fertile_status = detailedPetData.fertile_status
            state.detailedPetData.weight = detailedPetData.weight
        }
    },
        
    actions: {
        addPetEditedCust(state: any, newPet: Pet) {

            console.log(newPet)
            state.commit('setEditedCustAddPet', newPet)

        },

        editPetEditedCust(state: any, editedPet: Pet) {
            console.log("editPetEditedCust")
            console.log(editedPet)
            state.commit('setEditedCustEditPet', editedPet)

        },

        savePetToBeDeleted(state: any, petId: number) {
            console.log(petId)
            state.commit('setEditedCustAddPetIdToDelete', petId)
        },

        removePetEditedCustomer(state: any, pet_pos: number) {
            console.log(pet_pos)
            state.commit('setEditedCustRemovePet', pet_pos)
        },

        iniEditedCustNewPet(state: any) {
            state.commit('setEditedCustIniPet')
        },

        async iniEditedCustomer(state: any, customerEmail: string) {

            console.log("iniEditedCustomer")

            return (new Promise((resolve, reject) => {
                fetch(process.env.BASE_URL + `api/users/customer/byemail/` + customerEmail,
                    {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${state.getters.token}`,
                        }
                    })
                    .then((res) => res.json())
                    .then((customer_data) => {
                        console.log(customer_data);
                        console.log(customer_data.user_id);
                        state.commit('setEditedCustData', customer_data)
                        console.log(customer_data.email);
                        resolve(customer_data)
                    })
                    .catch(err => reject(err))
            }))

        },

        async iniCustDetailing(state: any, customerData: any) {

            console.log("iniCustDetailing")

            console.log(customerData);
            console.log(customerData.user_id);
            state.commit('setEditedCustData', customerData)
        },

        async iniEditedCustomerPets(state: any, customerId: number) {

            console.log("iniEditedCustomerPets");
            console.log(customerId);
            console.log(process.env.BASE_URL + `api/pets/customer/` + customerId.toString());



            return (new Promise((resolve, reject) => {
                fetch(process.env.BASE_URL + `api/pets/customer/` + customerId.toString(),
                    {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${state.getters.token}`,
                        }
                    })
                    .then((res) => res.json())
                    .then((customerpets_data) => {
                        console.log(customerpets_data);
                        console.log(typeof (customerpets_data));
                        state.commit('setEditedUserPetsData', customerpets_data)
                        resolve(customerpets_data)
                    })
                    .catch(err => reject(err))
            }))

        },

        async iniDetailedPetData(state: any, petId: number) {

            console.log("iniDetailedPetData");
            console.log(petId);
            console.log(process.env.BASE_URL + `api/pets/byid/` + petId.toString());



            return (new Promise((resolve, reject) => {
                fetch(process.env.BASE_URL + `api/pets/byid/` + petId.toString(),
                    {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${state.getters.token}`,
                        }
                    })
                    .then((res) => res.json())
                    .then((pet_data) => {
                        console.log(pet_data);
                        console.log(typeof (pet_data));
                        state.commit('setDetailedPetData', pet_data)
                        resolve(pet_data)
                    })
                    .catch(err => reject(err))
            }))

        },

        iniCustomerEditing(state: any) {

            state.commit('setEditingCustomer', true);
            state.commit('setEditedCustPetsToDelete', [])
        },

        endCustomerEditing(state: any) {

            state.commit('setEditingCustomer', false);
            state.commit('setEditedCustPetsToDelete', [])
        },



        async editCustomer(state: any, editedCustomerData: Customer) {
            console.log(JSON.stringify(editedCustomerData))
            const response = await fetch(process.env.BASE_URL + `api/users/customer/` + editedCustomerData.user_id.toString(), {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${state.getters.token}`,
                },
                body: JSON.stringify(editedCustomerData),
            })

            var editedCustomerId: number = 0

            if (response.ok) {
                const resjson = await response.json()
                editedCustomerId = resjson.user_id

            } else {
                throw new Error('error_updating_customer')
            }
            return (editedCustomerId)
        },

        resetEditedCustBreed(state: any) {
            state.commit('setEditedCustIniBreed')
        },

        async updateEditedCustBreedsList(state: any, params: UpdateEditedCustBreedsListParams) {
            if (params.species == '' || params.species == 'Other') {
                state.commit('setEditedCustIniBreeds')
            }
            else
            {
                return new Promise((resolve, reject) => {
                    fetch(process.env.BASE_URL + `api/pets/breeds/` + params.species + '/' + params.language, {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${state.getters.token}`,
                        }
                    })
                        .then(res => res.json())
                        .then(breeds => {
                            state.commit('setEditedCustBreeds', breeds)
                            resolve(breeds)
                        })
                        .catch(err => reject(err))
                })
            }

        },

        async createEditedCustPet(state: any, newPetData: Pet) {
            console.log(JSON.stringify(newPetData))
            const response = await fetch(process.env.BASE_URL + `api/pets`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${state.getters.token}`,
                },
                body: JSON.stringify(newPetData),
            })
            if (!response.ok) {
                throw new Error('error_creating_new_pet')
            }
        },

        async updateEditedCustPet(state: any, newPetData: Pet) {
            console.log(JSON.stringify(newPetData))
            const response = await fetch(process.env.BASE_URL + `api/pets/` + newPetData.pet_id.toString(), {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${state.getters.token}`,
                },
                body: JSON.stringify(newPetData),
            })
            if (!response.ok) {
                throw new Error('error_updating_pet')
            }
        },

        async deleteEditedCustPet(state: any, petId: number) {
            console.log(petId)
            const response = await fetch(process.env.BASE_URL + `api/pets/` + petId.toString(), {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${state.getters.token}`,
                },
            })
            if (!response.ok) {
                throw new Error('error_deleting_pet')
            }
        },

        async toggleEditedCustPetEditing(state: any, editingPet: boolean) {
            console.log("toggleEditedCustPetEditing")
            console.log(editingPet)
            state.commit('setEditedCustEditingPet', !editingPet)
        },

        async iniEditedCustPetEditing(state: any, editingPetData: Pet) {
            console.log("iniEditedCustPetEditing")
            console.log(editingPetData)
            state.commit('setEditedCustPetNewData', editingPetData)
        },

        async iniPetRecord(state : any, { editingPetData, language }: { editingPetData: Pet; language: string }) {
            console.log("iniPetRecord");
            console.log(editingPetData)
            state.commit('setEditedCustPetNewData', editingPetData)
            console.log(editingPetData.pet_id);
            console.log(process.env.BASE_URL + `api/pets/record/` + editingPetData.pet_id.toString( ) + '/' + language);

            return (new Promise((resolve, reject) => {
                fetch(process.env.BASE_URL + `api/pets/record/` + editingPetData.pet_id.toString()+ '/' + language,
                    {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${state.getters.token}`,
                        }
                    })
                    .then((res) => res.json())
                    .then((petrecord_data) => {
                        console.log(petrecord_data);
                        console.log(typeof (petrecord_data));
                        state.commit('setPetRecord', petrecord_data)
                        resolve(petrecord_data)
                    })
                    .catch(err => reject(err))
            }))

        },

        async iniDetailedPetRecord(state : any, { petId, language }: { petId: number; language: string }) {
            console.log("iniDetailedPetRecord");
            console.log(petId)
            console.log(process.env.BASE_URL + `api/pets/record/` + petId.toString( ) + '/' + language);

            return (new Promise((resolve, reject) => {
                fetch(process.env.BASE_URL + `api/pets/record/` + petId.toString()+ '/' + language,
                    {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${state.getters.token}`,
                        }
                    })
                    .then((res) => res.json())
                    .then((petrecord_data) => {
                        console.log(petrecord_data);
                        console.log(typeof (petrecord_data));
                        state.commit('setPetRecord', petrecord_data)
                        resolve(petrecord_data)
                    })
                    .catch(err => reject(err))
            }))

        },

        toggleShowingPetRecord(state: any, showingPetRecord: boolean) {
            console.log("toggleShowingPetRecord")
            state.commit('setShowingPetRecord', !showingPetRecord)
        },

    }
}
