<template>
  <td class="mx-0 px-0">
    <div class="select is-fullwidth is-small mx-0">
      <select v-model="editedStart" type="time" style="width: 70px;" required>
        <option v-for="timeLim of store.getters.getTimeLimits" :key="timeLim.time_limit_id" :value="timeLim.time_limit">
          {{ timeLim.time_limit }}
        </option>
      </select>
    </div>
  </td>
  <td class="mx-0 px-0">
    <div class="mx-0 px-0">-</div>
  </td>
  <td class="mx-0 px-0">
    <div class="select is-fullwidth is-small mx-0">
      <select v-model="editedEnd" type="time" style="width: 70px;" required>
        <option v-for="timeLim of store.getters.getTimeLimits" :key="timeLim.time_limit_id" :value="timeLim.time_limit">
          {{ timeLim.time_limit }}
        </option>
      </select>
    </div>
  </td>
  <td class="mx-0 px-0">
    <div class="is-flex is-justify-content-start mx-0">
      <button type="button" class="button is-primary is-light is-small is-rounded " @click="removeWorkingInterval($t)">
        <i class="fa-solid fa-minus"></i>
      </button>
    </div>
  </td>
</template>


<script lang="ts">
import { defineComponent, defineProps, ref, watch, defineEmits} from 'vue'
import { useStore } from "vuex";
import { Notyf } from "notyf";
import { useI18n } from 'vue-i18n';

const notyf = new Notyf()

export default defineComponent({
  name: "WorkingIntervalTableRow",
})

</script>
<script lang="ts" setup>

const props = defineProps(['intervalData', 'extractDateFromDayOfWeek'])
const store = useStore()
const { intervalData } = props
const { t } = useI18n();

const editedStart = ref(intervalData.start)
const editedEnd = ref(intervalData.end)

const emit = defineEmits(['changes-made'])

/* Old version. Several profiles
const removeWorkingInterval = ($t : any) => {

  if (confirm($t && $t('Vet.ConfirmAvailIntervalDelete'))) {
    //TODO:Create notification to ask if it is really to delete
    var editedDaySchedules: any
    console.log("remove")
    console.log(intervalData)
    editedDaySchedules = store.getters.getEditedDaySchedules
    console.log(editedDaySchedules)
    editedDaySchedules.forEach((daySchedule: any) => {
      if (daySchedule.dayOfWeek == intervalData.dayOfWeek) {
        daySchedule.workingIntervals.splice(intervalData.pos, 1)
      }
    })
    try {
      // Update day schedules
      store.dispatch('updateEditedDaySchedules', editedDaySchedules)
      console.log(store.getters.getEditedDaySchedules)
      notyf.success($t('Vet.NotyfAvailIntervalRemoved'))
      emit('changes-made', true) // Emit event indicating changes were made
    } catch (e) {
      notyf.error($t('Vet.NotyfErrorRemoveAvailInterval'))
    }
  }
}
*/

// New version. Unique profile

const removeWorkingInterval = ($t : any) => {

if (confirm($t && $t('Vet.ConfirmAvailIntervalDelete'))) {
  
  console.log("remove")
  console.log(intervalData)
  
  const availabilityIntervals = store.getters.getAvailabilityIntervals;
  console.log("Availability Intervals:", availabilityIntervals);
      
  const dayDateStr = props.extractDateFromDayOfWeek(intervalData.dayOfWeek);

  var editedDaySchedules = availabilityIntervals.filter((interval: any) => interval.dateStartInclusive == dayDateStr ).slice();
      
  console.log("Filtered Day Schedules:", editedDaySchedules);

  const daySchedule = editedDaySchedules[0];
      
  console.log("Day Schedule:", daySchedule);
  
  // remove position intervalDate.pos in daySchedule.hours

  daySchedule.hours.splice(intervalData.pos, 1);

  try {
    // Update day schedules
    store.dispatch('updateEditedDaySchedules', editedDaySchedules)
    console.log(store.getters.getEditedDaySchedules)
    notyf.success($t('Vet.NotyfAvailIntervalRemoved'))
    emit('changes-made', true) // Emit event indicating changes were made
  } catch (e) {
    notyf.error($t('Vet.NotyfErrorRemoveAvailInterval'))
  }
}
}

watch(() => props.intervalData, (newValue) => {
  editedStart.value = newValue.start
  editedEnd.value = newValue.end
})

const updateWorkingInterval = () => {
  const editedDaySchedules = store.getters.getEditedDaySchedules

  editedDaySchedules.forEach((daySchedule: any) => {
    if (daySchedule.dayOfWeek === intervalData.dayOfWeek) {
      const intervalIndex = daySchedule.workingIntervals.findIndex((interval: any) => interval.pos === intervalData.pos)
      if (intervalIndex !== -1) {
        if (editedEnd.value > editedStart.value) {
          daySchedule.workingIntervals[intervalIndex].start = editedStart.value
          daySchedule.workingIntervals[intervalIndex].end = editedEnd.value
        }
        else {
          notyf.error(t('Vet.NotyfErrorStartEndAvailInterval'))
          editedStart.value = daySchedule.workingIntervals[intervalIndex].start
          editedEnd.value = daySchedule.workingIntervals[intervalIndex].end
        }
      }
    }
  })
  store.dispatch('updateEditedDaySchedules', editedDaySchedules)
  emit('changes-made', true) // Emit event indicating changes were made
}

watch(editedStart, updateWorkingInterval)
watch(editedEnd, updateWorkingInterval)

</script>
<style scoped>
.pointer {
  cursor: pointer;
}

.custom-margin {
  margin-left: 0;
  margin-right: 0;
}
</style>
