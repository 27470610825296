<template>
  <div>
    <Navbar></Navbar>
    <section class="hero">
      <div class="hero-body">
        <div class="container">
          <div class="content">
            <form @submit.prevent="createCustomerMeth">
              <div class="content-body">
                <form @submit.prevent="createCustomerMeth">
                  <div class="header">
                    <h5 class="title is-5">
                      {{ $t('CustomerData.PleaseEnterYourCustomerData') }}
                    </h5>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <div class="field">
                        <label class="label">{{ $t('CustomerData.Name') }}</label>
                        <div class="control">
                          <input :minlength='2' v-model="getNewCustomerData.name" class="input" type="text"
                          :placeholder="$t('CustomerData.NamePlaceholder')" required>
                        </div>
                      </div>
                    </div>
                    <div class="column">
                      <div class="field">
                        <label class="label">{{ $t('CustomerData.Email') }}</label>
                        <div class="control">
                          <input v-model="getNewCustomerData.email" class="input" type="email"
                          :placeholder="replaceSpecialCharacters($t('CustomerData.EmailPlaceholder'))" required>
                        </div>
                      </div>
                    </div>
                    <div class="column">
                      <div class="field">
                        <label class="label">{{ $t('UserData.PhoneNumber') }}</label>
                        <div class="control">
                          <input :minlength='6' v-model="getNewCustomerData.phone" class="input" type="tel"
                          :placeholder="$t('UserData.PhoneNumberPlaceholder')" required>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <div class="field">
                        <label class="label">{{ $t('CustomerData.VATNumber') }}</label>
                        <div class="control">
                          <input v-model="getNewCustomerData.tax_id" class="input" type="text"
                          :placeholder="$t('CustomerData.VATNumberPlaceholder')" required>
                        </div>
                      </div>
                    </div>
                    <div class="column">
                      <div class="field">
                        <label class="label">{{ $t('CustomerData.Password') }}</label>
                        <div class="control">
                          <input :minlength='6' v-model="getNewCustomerData.password" class="input" type="password"
                          :placeholder="$t('CustomerData.PasswordPlaceholder')" required>
                        </div>
                      </div>
                    </div>
                    <div class="column">
                      <div class="field">
                        <label class="label">{{ $t('CustomerData.PassConfirmation') }}</label>
                        <div class="control">
                          <input :minlength='6' v-model="getNewCustomerData.confirmation" class="input" type="password"
                          :placeholder="$t('CustomerData.PassConfirmationPlaceholder')" required>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column is-6 ">
                      <div class="notification is-light is-small" v-html="$t('CustomerData.PostalCodeSearchNote')">
                      </div>
                      <!-- Tooltip version
                      <span class="icon has-tooltip-arrow has-tooltip-right" :data-tooltip="$t('CustomerData.PostalCodeSearchNote')">
                          <i class="fas fa-info-circle has-text-success"></i>
                      </span>
                      -->
                    </div>
                    <div class="column is-2">
                      <div class="field">
                        <label class="label">{{ $t('CustomerData.PostalCode') }}</label>
                        <div class="control">
                          <input v-model="getNewCustomerData.postal_code" class="input" type="text"
                          :placeholder="$t('CustomerData.PostalCodePlaceholder')" required>
                        </div>
                      </div>
                    </div>
                    <div class="column is-4">
                      <div class="field">
                        <label class="label">{{ $t('CustomerData.City') }}</label>
                        <div class="control" :class="{ 'is-loading': postalCodeIsLoading }">
                          <input v-model="getNewCustomerData.city" class="input" type="text"
                          :placeholder="$t('CustomerData.CityPlaceholder')" required>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column is-half">
                      <div class="field">
                        <label class="label">{{ $t('CustomerData.Address') }}</label>
                        <div class="control">
                          <input v-model="getNewCustomerData.address" class="input" type="text"
                          :placeholder="$t('CustomerData.AddressPlaceholder')" required>
                        </div>
                      </div>
                    </div>
                    <div class="column is-one-quarter">
                      <div class="field">
                        <label class="label">{{ $t('CustomerData.State') }}</label>
                        <div class="control">
                          <input v-model="getNewCustomerData.state" class="input" type="text"
                          :placeholder="$t('CustomerData.StatePlaceholder')" required>
                        </div>
                      </div>
                    </div>
                    <div class="column is-one-quarter">
                      <div class="field">
                        <label class="label">{{ $t('CustomerData.Country') }}</label>
                        <div class="control">
                          <input v-model="getNewCustomerData.country" class="input" type="text"
                          :placeholder="$t('CustomerData.CountryPlaceholder')" required>
                        </div>
                      </div>
                    </div>
                    <!--Vet Center
                    <div class="column">
                      <div class="field">
                        <label class="label">{{ $t('CustomerData.VetCenter') }}</label>
                        <div class="control">
                          <input v-model="getNewCustomerData.vet_center" class="input" type="email"
                          :placeholder="$t('CustomerData.VetCenterPlaceholder')" required>
                        </div>
                      </div>
                    </div>
                    -->                  
                  </div>
                </form>
              </div>
              <!-- Adding pets in this page was deactivated due to security concerns
              
              <div class="header">
                <h5 class="title is-6 mt-4" v-if="!addingPet && !getEditingPet">
                  {{ $t('CustomerData.YourPets') }}
                </h5>
              </div>
              <div class="content-body">
                <table class="table" v-if="!addingPet && !getEditingPet">
                  <thead>
                    <tr>
                      <th class="">{{ $t('PetData.PetName') }}</th>
                      <th class="">{{ $t('PetData.Species') }}</th>
                      <th class="">{{ $t('PetData.Breed') }}</th>
                      <th class="">{{ $t('PetData.Gender') }}</th>
                      <th class="">{{ $t('PetData.Birth') }}</th>
                      <th class="">{{ $t('PetData.Age') }}</th>
                      <th class="">{{ $t('PetData.FertileStatus') }}</th>
                      <th class="">{{ $t('PetData.Weight') }}</th>
                      <th>{{ $t('General.Operations') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="pet of getNewCustomerPets" :key="pet.pet_id">
                      <PetTableRow :pet-data="pet"></PetTableRow>
                    </tr>
                  </tbody>
                </table>
              </div>
              
              <div class="content-body">
                <div class="header is-flex is-justify-content-space-between" v-if="!addingPet && !getEditingPet">
                  <div>
                    <button class="button is-primary" @click="toggleAddingPet"><strong>{{ $t('CustomerData.NewPet') }}</strong></button>
                  </div>
                </div>
              </div>
              -->
              <div class="content-body">
                <div class="field is-grouped is-grouped-right">
                  <p class="control" v-if="!addingPet && !getEditingPet">
                    <button type="submit" class="button is-primary">{{ $t('General.Save') }}</button>
                  </p>
                  <p class="control" v-if="!addingPet && !getEditingPet">
                    <a @click="cancelRegistration" class="button is-light">
                      {{ $t('General.Cancel') }}
                    </a>
                  </p>
                </div>
              </div>
              
              <!-- Adding pets in this page was deactivated due to security concerns
              <div class="content-body">
                <form @submit.prevent="addPetMeth" v-if="addingPet && !getEditingPet">
                  <div class="header">
                    <h5 class="title is-5">
                      {{ $t('CustomerData.AddNewPet') }}
                    </h5>
                  </div>
                  <PetDataForm ref="refNewPetDataForm"></PetDataForm>
                  <div class="field is-grouped is-grouped-right">
                    <p class="control">
                      <button type="submit" class="button is-primary">{{ $t('General.Add') }}</button>
                    </p>
                    <p class="control">
                      <a @click="toggleAddingPet" class="button is-light">
                        {{ $t('General.Cancel') }}
                      </a>
                    </p>
                  </div>
                </form>
              </div>
              <div class="content-body">
                <form @submit.prevent="editPetMeth" v-if="getEditingPet">
                  <div class="header">
                    <h5 class="title is-5">
                      {{ $t('CustomerData.EditPet') }}
                    </h5>
                  </div>
                  <PetDataForm ref="refEditedPetDataForm"></PetDataForm>
                  <div class="field is-grouped is-grouped-right">
                    <p class="control">
                      <button type="submit" class="button is-primary">{{ $t('General.Edit') }}</button>
                    </p>
                    <p class="control">
                      <a @click="cancelEditPet" class="button is-light">
                        {{ $t('General.Cancel') }}
                      </a>
                    </p>
                  </div>
                </form>
              </div>
              -->
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import Navbar from "../components/Navbar.vue";
import { Notyf } from "notyf";
// Adding pets in this page was deactivated due to security concerns
//import PetTableRow from "../components/PetTableRow.vue";
//import PetDataForm from "../components/PetDataForm.vue";

const notyf = new Notyf()

export default defineComponent({
  name: "CustomerRegistration",
  components: { /*PetTableRow,*/ Navbar, /*PetDataForm*/},

  data() {
    return {
      componentName: "CustomerRegistration",
      addingPet: false,
      otherSpecies: false,
      postalCodeIsLoading: false,
    }
  },
  computed: {
    ...mapGetters(['getNewCustomerData', 'getNewCustomerPets', 'getNewPetData', 'getBreeds', 'getEditingPet', 'getInitialPetData', 'userData']),
    validPassword(): boolean {
      return (this.getNewCustomerData.password === this.getNewCustomerData.confirmation && this.getNewCustomerData.password.length >= 6)
    },
  },
  methods:
  {
    ...mapActions([/*'addPet', 'iniNewPet',*/ 'checkEmailIsTaken', 'createCustomer', /*'createPet',*/ 'iniNewCustomer'/*,
      'updateBreeds', 'fetchPets', 'deletePet', 'toggleEditingPet', 'editPet'*/]),

    replaceSpecialCharacters(text: string)
    {
      console.log('Original text:', text);
      // Regular expression to replace '%at%' with '@'
      const replacedText = text.replace('%at%', '@');
      console.log('Replaced text:', replacedText);
      return replacedText;
    },

    async createCustomerMeth()
    {
      //Check if fields are filled
      if (!this.getNewCustomerData.name || !this.getNewCustomerData.email || !this.getNewCustomerData.phone || !this.getNewCustomerData.tax_id ||
          !this.getNewCustomerData.password || !this.getNewCustomerData.confirmation || !this.getNewCustomerData.address) {
        notyf.error(this.$t('CustomerData.NotyfFillAllFields'))
        return
      }
      const checkEmailIsTaken = await this.checkEmailIsTaken(this.getNewCustomerData.email)
      if (!checkEmailIsTaken) {
        if (this.validPassword) {
          try {
            var newCustomerId: number = await this.createCustomer(this.getNewCustomerData)
            console.log(newCustomerId)

            if (newCustomerId) {
              /*Adding pets in this page was deactivated due to security concerns
              //Create Pets in the customer
              for (var newPetData of this.getNewCustomerPets) {
                newPetData.user_id = newCustomerId;
                await this.createPet(newPetData)
              }
              */
              this.clearFormCustomer()
              notyf.success(this.$t('UserData.NotyfNewUserAdded'))

              this.$router.push('/')
            }
          } catch (e) {
            notyf.error(this.$t('UserData.NotyfErrorCreatingUser'))
          }
        } else {
          notyf.error(this.$t('UserData.NotyfErrorPasswordsMismatch'))
        }
      } else {
        notyf.error(this.$t('CustomerData.NotyfEmailInUse'))
      }
    },

    cancelRegistration()
    {
      this.clearFormCustomer()
      this.$router.push("/")
    },

    /*async deleteBtn(id: string, $t: any) {
      if (confirm($t && $t('PetData.ConfirmDelete'))) {
        //TODO:Create notification to ask if it is really to delete
        try {
          await this.deletePet(id);
          notyf.success($t('PetData.NotyfPetRemoved'))
        } catch (e) {
          notyf.error($t('PetData.NotyfErrorRemovePet'))
        }
      }
    },*/

    /* Adding pets in this page was deactivated due to security concerns
    async addPetMeth() {
      this.toggleAddingPet();
      console.log(this.getNewCustomerPets)
      this.getNewPetData.pet_id = this.getNewCustomerPets.length
      this.addPet(this.getNewPetData)
      console.log(this.getNewCustomerPets);
      // Cleans the form with pet data using method in child component
      // this.clearFormPet()
      (this.$refs.refNewPetDataForm as any).clearFormPet();
      notyf.success(this.$t('CustomerData.NotyfNewPetAdded'))
    },
    */

    /* Adding pets in this page was deactivated due to security concerns
    async editPetMeth() {
      console.log(this.getNewCustomerPets)
      console.log(this.getNewPetData)
      console.log('editPetMeth')
      this.editPet(this.getNewPetData)
      console.log(this.getNewCustomerPets)
      this.toggleEditingPet(true);
      // Cleans the form with pet data using method in child component
      // this.clearFormPet()
      (this.$refs.refEditedPetDataForm as any).clearFormPet();
      notyf.success(this.$t('CustomerData.NotyfPetModified'))
    },

    cancelEditPet() {
      console.log('cancelPetMeth');
      (this.$refs.refEditedPetDataForm as any).clearFormPet();
      this.toggleEditingPet(true)
    },
    */


    clearFormCustomer()
    {
      this.iniNewCustomer()
    },

    fetchDetailsByPostalCode(postalCode: string)
    {
      const postalCodePattern: { [key: string]: RegExp } =
      {
        'United States': /^\d{5}$/,
        'Portugal': /^\d{4}-\d{3}$/,
        'Brasil': /^\d{5}-\d{3}$/
      };
            
      switch (process.env.COUNTRY) {
          case 'United States':
          if (!postalCodePattern['United States'].test(postalCode))
            {
              this.getNewCustomerData.city = this.$t('CustomerData.InvalidPostalCode');
              this.getNewCustomerData.state = '';
              this.getNewCustomerData.address = '';
              this.getNewCustomerData.country = process.env.COUNTRY;
              return;
            }
            this.postalCodeIsLoading = true;
            fetch(`https://api.zippopotam.us/us/${postalCode}`)
              .then(response => response.json())
              .then(data => {
                if(data.erro)
                {
                  this.getNewCustomerData.city = this.$t('CustomerData.InvalidPostalCode');
                  this.getNewCustomerData.state = '';
                  this.getNewCustomerData.address = '';
                  this.getNewCustomerData.country = process.env.COUNTRY;
                }
                else
                {
                  this.getNewCustomerData.city = data.places[0]['place name'];
                  this.getNewCustomerData.state = data.places[0]['state'];
                  this.getNewCustomerData.address = '';
                  this.getNewCustomerData.country = process.env.COUNTRY;
                }
              })
              .catch(error => {
                console.error('Error fetching details:', error);
              })
              .finally(() => {
                this.postalCodeIsLoading = false;
              });
              break;
          case 'Portugal':
            if (!postalCodePattern['Portugal'].test(postalCode))
            {
              this.getNewCustomerData.city = this.$t('CustomerData.InvalidPostalCode');
              this.getNewCustomerData.state = '';
              this.getNewCustomerData.address = '';
              this.getNewCustomerData.country = process.env.COUNTRY;
              return;
            }
            this.postalCodeIsLoading = true;
            /* Using GeoAPI
            fetch(`https://json.geoapi.pt/cp/${postalCode}`)
              .then(response => response.json())
              .then(data => {
                this.getNewCustomerData.city = data.Localidade;;
                this.getNewCustomerData.state = data.Distrito;
                this.getNewCustomerData.address = data.partes[0].Artéria;
                this.getNewCustomerData.country = process.env.COUNTRY;
              })
              .catch(error => {
                console.error('Error fetching details:', error);
              })
              .finally(() => {
                this.postalCodeIsLoading = false;
              });
            */
            /* Using CTT Codigo Postal API 30 requests/min, 10000/month*/
            fetch(`https://www.cttcodigopostal.pt/api/v1/297a6f4ff9fc4acc9d4e65e260683d13/${postalCode}`)
            .then(response => response.json())
            .then(data => {
              this.getNewCustomerData.city = data[0].localidade;
              this.getNewCustomerData.state = data[0].distrito;
              this.getNewCustomerData.address = data[0].morada;
              this.getNewCustomerData.country = process.env.COUNTRY;
            })
            .catch(error => {
              console.error('Error fetching details:', error);
            })
            .finally(() => {
              this.postalCodeIsLoading = false;
            });
            

            break;
          case 'Brasil':
            if (!postalCodePattern['Brasil'].test(postalCode))
            {
              this.getNewCustomerData.city = this.$t('CustomerData.InvalidPostalCode');
              this.getNewCustomerData.state = '';
              this.getNewCustomerData.address = '';
              this.getNewCustomerData.country = process.env.COUNTRY;
              return;
            }
            this.postalCodeIsLoading = true;
            const cleanedPostalCode = postalCode.replace(/-/g, '');
            
            /* With Brasil API
            fetch(`https://brasilapi.com.br/api/cep/v1/${cleanedPostalCode}`)
              .then(response => response.json())
              .then(data => {
                this.getNewCustomerData.city = data.city;
                this.getNewCustomerData.state = data.state;
                this.getNewCustomerData.address = data.street;
                this.getNewCustomerData.country = process.env.COUNTRY;
              })
              .catch(error => {
                console.error('Error fetching details:', error);
              })
              .finally(() => {
                this.postalCodeIsLoading = false;
              });
              break;
            */

            /* With ViaCEP API 300 requests/hour */
            fetch(`https://viacep.com.br/ws/${cleanedPostalCode}/json/`)
              .then(response => response.json())
              .then(data => {
                if(data.erro)
                {
                  this.getNewCustomerData.city = this.$t('CustomerData.InvalidPostalCode');
                  this.getNewCustomerData.state = '';
                  this.getNewCustomerData.address = '';
                  this.getNewCustomerData.country = process.env.COUNTRY;
                }
                else
                {
                  this.getNewCustomerData.city = data.localidade;
                  this.getNewCustomerData.state = data.estado;
                  this.getNewCustomerData.address = data.logradouro + ', Bairro ' + data.bairro;
                  this.getNewCustomerData.country = process.env.COUNTRY;
                }
              })
              .catch(error => {
                console.error('Error fetching details:', error);
              })
              .finally(() => {
                this.postalCodeIsLoading = false;
              });
              break;
          default:
            throw new Error('Unsupported country');
        }
    }

    /* Adding pets in this page was deactivated due to security concerns
    toggleAddingPet() {
      this.addingPet = !this.addingPet
    },
    */
  },
  watch:
  {
    'getNewCustomerData.postal_code'(newPostalCode) {
      this.fetchDetailsByPostalCode(newPostalCode);
    }
  },
  mounted() { }
})
</script>

<style scoped>
.content-body {
  margin-top: 10px;
}

.content-body {
  margin-top: 10px;
}
.icon[data-tooltip] {
  position: relative;
  cursor: pointer;
}

.icon[data-tooltip]::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 125%; /* Adjusted position */
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 0.5rem; /* Reduced padding */
  border-radius: 4px; /* Slightly rounded corners */
  white-space: normal;
  max-width: 250px; /* Limited width for smaller tooltips */
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 1000;
}

.icon[data-tooltip]:hover::after,
.icon[data-tooltip]:focus::after,
.icon[data-tooltip]:active::after {
  opacity: 1;
  pointer-events: auto;
}
</style>